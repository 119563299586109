<template>
    <div id="hoe-left-panel">
        <div style="display: flex">
            <div id="headerSmall" style="height: 50px; width: 50px; line-height: 50px">
                <router-link :to="{ name: 'home' }">
                    <img src="~/@/assets/images/hoerb_icon.png" height="24px" alt="hörb" style="margin: 13px" />
                </router-link>
            </div>
            <div id="headerBig" style="height: 50px; width: 50px; line-height: 50px">
                <router-link :to="{ name: 'home' }">
                    <img v-if="colorImageType" src="~/@/assets/images/horb_white.png" alt="hörb" style="margin: 13px; width: 170px" />
                    <img v-if="!colorImageType" src="~/@/assets/images/horb_verysml.png" alt="hörb" style="margin: 13px; width: 170px" />
                </router-link>
            </div>
            <div style="height: 50px; width: 50px; line-height: 50px" id="headerExpand">
                <div id="expandIconHeader" class="rotateIcon" style="position: absolute; top: 40px; cursor: pointer">
                    <font-awesome-icon :icon="['fad', 'chevron-left']" class="fa-stack-2x icon-background" />
                </div>
            </div>
        </div>

        <ul v-if="isAuthenticated && mandanten && mandanten.length > 0" class="nav panel-list" style="display: block">
            <li class="nav-level">Navigation</li>

            <li
                v-if="hasModuleBestellung && (hasRoleLagerist || hasRoleLagerAdmin || hasRoleAussendienstler || hasRoleAdmin || hasRoleBestellungenTechniker)"
                class="hoe-has-menu"
            >
                <a>
                    <font-awesome-icon :icon="['fad', 'cart-plus']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.entities.bestellung') }}
                    </span>
                    <FavoritesPin :single="false" :area="'bestellung'" />
                    <span class="selected" />
                </a>
                <ul class="hoe-sub-menu">
                    <li v-if="hasModuleBestellung && (hasRoleAussendienstler || hasRoleLagerAdmin || hasRoleAdmin || hasRoleBestellungenTechniker)">
                        <router-link :to="{ name: 'bestellung', params: { slug: -1, edit: true } }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'cart-plus']" fixed-width />&nbsp;
                            <span> Bestellung erstellen</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin bestellung hide'"
                            :params="buildPinObject('bestellung', { slug: -1, edit: true }, 'Bestellung erstellen')"
                        />
                    </li>
                    <li
                        v-if="
                            hasModuleBestellung &&
                            (hasRoleLagerist || hasRoleLagerAdmin || hasRoleAussendienstler || hasRoleAdmin || hasRoleBestellungenTechniker)
                        "
                    >
                        <router-link :to="{ name: 'bestellung_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'cart-plus']" fixed-width />&nbsp;
                            <span> Bestellungen</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin bestellung hide'"
                            :params="buildPinObject('bestellung_list', {}, 'Bestellungen')"
                        />
                    </li>
                </ul>
            </li>

            <li class="hoe-has-menu" v-if="hasModuleWebshop">
                <a>
                    <font-awesome-icon :icon="['fad', 'store']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.entities.webshop') }}
                    </span>
                </a>
                <ul class="hoe-sub-menu">
                    <li>
                        <router-link :to="{ name: 'orders_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'store']" fixed-width />
                            <span> &nbsp;{{ $t('menu.entities.webshop_orders') }} </span>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'webshop_category_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'inventory']" fixed-width />
                            <span> &nbsp;{{ $t('menu.entities.webshop_category') }} </span>
                        </router-link>
                    </li>
                </ul>
            </li>

            <li
                v-if="hasModuleRechnungslegung && (hasRoleLagerist || hasRoleLagerAdmin || hasRoleAussendienstler || hasRoleAdmin || hasRoleUser)"
                class="hoe-has-menu"
            >
                <a>
                    <font-awesome-icon :icon="['fad', 'folder-open']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.entities.beleg') }}
                    </span>

                    <FavoritesPin :single="false" :area="'beleg'" />
                    <span class="selected" />
                </a>
                <ul class="hoe-sub-menu">
                    <li v-if="hasModuleRechnungslegung && hasRoleUser">
                        <router-link
                            :to="{ name: 'beleg', params: { slug: -1, edit: true, duplicate: false } }"
                            data-toggle="collapse"
                            data-target=".navbar-collapse.in"
                        >
                            <font-awesome-icon :icon="['fad', 'file-plus']" fixed-width />
                            <span> Neuen Beleg erstellen</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin beleg hide'"
                            :params="buildPinObject('beleg', { slug: -1, edit: true, duplicate: false }, 'Neuen Beleg erstellen')"
                        />
                    </li>
                    <li v-if="hasModuleRechnungslegung && hasRoleUser">
                        <router-link :to="{ name: 'beleg_list', params: { duplicate: false } }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'folder-open']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.belege') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin beleg hide'"
                            :params="buildPinObject('beleg_list', { duplicate: false }, $t('menu.entities.belege'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'rechnungsvorlage_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'file-image']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.rechnungsvorlagen') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin beleg hide'"
                            :params="buildPinObject('rechnungsvorlage_list', {}, $t('menu.entities.rechnungsvorlagen'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'belegtext_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'align-left']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.belegstexte') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin beleg hide'"
                            :params="buildPinObject('belegtext_list', {}, $t('menu.entities.belegstexte'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'belegfelddefinition_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'ellipsis-v']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.belegfelddefinition') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin beleg hide'"
                            :params="buildPinObject('belegfelddefinition_list', {}, $t('menu.entities.belegfelddefinition'))"
                        />
                    </li>
                </ul>
            </li>
            <li v-if="hasModuleKassabuch && hasRoleUser" class="hoe-hasnt-menu">
                <router-link :to="{ name: 'kassabuch_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                    <font-awesome-icon :icon="['fad', 'book']" fixed-width />&nbsp;
                    <span class="menu-text">{{ $t('menu.entities.kassabuch') }}</span>
                </router-link>
                <FavoritesPin
                    :key="selectedMandant.id"
                    :class-style="'pin kassabuch single'"
                    :params="buildPinObject('kassabuch_list', {}, $t('menu.entities.kassabuch'))"
                />
            </li>

            <li v-if="hasModuleArtikelkatalog || hasRoleArtikelWrite" class="hoe-hasnt-menu">
                <router-link :to="{ name: 'artikelkatalog' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                    <font-awesome-icon :icon="['fad', 'swatchbook']" fixed-width />&nbsp;
                    <span class="menu-text">{{ $t('menu.entities.artikelkatalog') }}</span>
                </router-link>
                <FavoritesPin
                    :key="selectedMandant.id"
                    :class-style="'pin artikelkatalog single'"
                    :params="buildPinObject('artikelkatalog', {}, $t('menu.entities.artikelkatalog'))"
                />
            </li>

            <li v-if="hasModuleZahlung && hasRoleUser" class="hoe-has-menu">
                <a>
                    <font-awesome-icon :icon="['fad', 'money-bill-alt']" fixed-width />&nbsp;
                    <span class="menu-text">
                        {{ $t('menu.entities.zahlung') }}
                    </span>
                    <FavoritesPin :single="false" :area="'zahlung'" />
                    <span class="selected" />
                </a>
                <ul class="hoe-sub-menu">
                    <li>
                        <router-link :to="{ name: 'zahlung_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'money-bill-alt']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.zahlung') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin zahlung hide'"
                            :params="buildPinObject('zahlung_list', {}, $t('menu.entities.zahlung'))"
                        />
                    </li>

                    <li>
                        <router-link :to="{ name: 'zahlung_report' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'download']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.zahlungreport') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin zahlung hide'"
                            :params="buildPinObject('zahlung_report', {}, $t('menu.entities.zahlungreport'))"
                        />
                    </li>
                </ul>
            </li>
            <li v-if="hasRoleUser || hasRoleLagerAdmin" class="hoe-has-menu">
                <a>
                    <font-awesome-icon :icon="['fad', 'tools']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.entities.administration') }}
                    </span>
                    <FavoritesPin :single="false" :area="'administration'" />
                    <span class="selected" />
                </a>

                <ul class="hoe-sub-menu">
                    <li>
                        <router-link :to="{ name: 'warengruppe_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'object-group']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.warengruppe') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('warengruppe_list', {}, $t('menu.entities.warengruppe'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'untergruppe_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'layer-group']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.untergruppe') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('untergruppe_list', {}, $t('menu.entities.untergruppe'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'artikel_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'tags']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.artikel') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('artikel_list', {}, $t('menu.entities.artikel'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'artikelfelddefinition_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'ellipsis-h-alt']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.artikelfelddefinition') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('artikelfelddefinition_list', {}, $t('menu.entities.artikelfelddefinition'))"
                        />
                    </li>
                    <li v-if="hasModuleArtikelkatalog">
                        <router-link :to="{ name: 'artikelkatalog' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'swatchbook']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.artikelkatalog') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('artikelkatalog', {}, $t('menu.entities.artikelkatalog'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'mehrwertssteuer_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'calculator']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.mehrwertssteuer') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('mehrwertssteuer_list', {}, $t('menu.entities.mehrwertssteuer'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'preisschiene_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'money-bill']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.preisschiene') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('preisschiene_list', {}, $t('menu.entities.preisschiene'))"
                        />
                    </li>
                    <li v-if="hasModuleRechnungslegung">
                        <router-link :to="{ name: 'zahlungsziel_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'bullseye-arrow']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.zahlungsziel') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('zahlungsziel_list', {}, $t('menu.entities.zahlungsziel'))"
                        />
                    </li>
                    <li v-if="hasModuleRechnungslegung">
                        <router-link :to="{ name: 'lieferbedingung_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'truck-loading']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.lieferbedingungen') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('lieferbedingung_list', {}, $t('menu.entities.lieferbedingungen'))"
                        />
                    </li>
                    <li v-if="hasModuleRechnungslegung">
                        <router-link :to="{ name: 'zahlungsbedingung_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'money-check-edit-alt']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.zahlungsbedingungen') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('zahlungsbedingung_list', {}, $t('menu.entities.zahlungsbedingungen'))"
                        />
                    </li>
                    <li v-if="hasModuleRechnungslegung">
                        <router-link :to="{ name: 'rabatt_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'percent']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.rabatt') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin administration hide'"
                            :params="buildPinObject('rabatt_list', {}, $t('menu.entities.rabatt'))"
                        />
                    </li>
                </ul>
            </li>

            <li v-if="hasModuleLagerplatz && (hasRoleLagerplatz || hasRoleAdmin)" class="hoe-has-menu">
                <a>
                    <font-awesome-icon :icon="['fad', 'warehouse-alt']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.entities.lager') }}
                    </span>
                    <FavoritesPin :single="false" :area="'lager'" />
                    <span class="selected" />
                </a>
                <ul class="hoe-sub-menu">
                    <li v-if="(hasModuleLagerplatz && hasRoleLagerplatz) || (hasModuleLagerplatz && hasRoleAdmin)">
                        <router-link :to="{ name: 'einauslagerung_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'dolly-flatbed']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.einauslagerung') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin lager hide'"
                            :params="buildPinObject('einauslagerung_list', {}, $t('menu.entities.einauslagerung'))"
                        />
                    </li>
                    <li v-if="hasModuleLagerplatz && (hasRoleLagerplatz || hasRoleAdmin)">
                        <router-link :to="{ name: 'lagerplaetze_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'inventory']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.lagerplaetze') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin lager hide'"
                            :params="buildPinObject('lagerplaetze_list', {}, $t('menu.entities.lagerplaetze'))"
                        />
                    </li>
                </ul>
            </li>

            <li v-if="hasModulePreisKalkulator && hasRolePreisKalkulator" class="hoe-has-menu">
                <router-link :to="{ name: 'preiskalkulator' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                    <font-awesome-icon :icon="['fad', 'dollar-sign']" fixed-width />
                    <span class="menu-text">{{ $t('menu.entities.preiskalk') }}</span>
                </router-link>
                <FavoritesPin
                    :key="selectedMandant.id"
                    :class-style="'pin preiskalk single'"
                    :params="buildPinObject('preiskalkulator', {}, $t('menu.entities.preiskalk'))"
                />
            </li>

            <li v-if="hasRoleUser" class="hoe-has-menu">
                <a>
                    <font-awesome-icon :icon="['fad', 'address-card']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.entities.kunden') }}
                    </span>
                    <FavoritesPin :single="false" :area="'kunden'" />
                    <span class="selected" />
                </a>
                <ul class="hoe-sub-menu">
                    <li>
                        <router-link :to="{ name: 'kunde_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'address-card']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.kunden') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin kunden hide'"
                            :params="buildPinObject('kunde_list', {}, $t('menu.entities.kunden'))"
                        />
                    </li>
                    <!--    <li>
                        <router-link :to="{ name: 'kundefelddefinition_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'ellipsis-h-alt']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.kundefelddefinition') }}</span>
                        </router-link>
                    </li> -->
                    <li>
                        <router-link :to="{ name: 'firmengruppe_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'building']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.firmengruppe') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin kunden hide'"
                            :params="buildPinObject('firmengruppe_list', {}, $t('menu.entities.firmengruppe'))"
                        />
                    </li>
                </ul>
            </li>
            <li v-if="hasModuleAuswertung && hasRoleUser" class="hoe-hasnt-menu">
                <router-link :to="{ name: 'auswertung' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                    <font-awesome-icon :icon="['fad', 'chart-bar']" fixed-width />&nbsp;
                    <span class="menu-text">{{ $t('menu.entities.auswertung') }}</span>
                </router-link>
                <FavoritesPin
                    :key="selectedMandant.id"
                    :class-style="'pin auswertung single'"
                    :params="buildPinObject('auswertung', {}, $t('menu.entities.auswertung'))"
                />
            </li>

            <li v-if="hasModuleZeiterfassung && (hasRoleZeiterfassung || hasRoleZeiterfassungAdmin)" class="hoe-has-menu">
                <a>
                    <font-awesome-icon :icon="['fad', 'clock']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.entities.zeiterfassung') }}
                    </span>
                    <FavoritesPin :single="false" :area="'zeiterfassung'" />
                    <span class="selected" />
                </a>
                <ul class="hoe-sub-menu">
                    <li v-if="hasModuleProject && hasRoleProject">
                        <router-link :to="{ name: 'projekt_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'project-diagram']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.projekt') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin zeiterfassung hide'"
                            :params="buildPinObject('projekt_list', {}, $t('menu.entities.projekt'))"
                        />
                    </li>
                    <li v-if="hasRoleZeiterfassung">
                        <router-link :to="{ name: 'zeiterfassung' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'clock']" fixed-width />&nbsp;
                            <span>
                                {{ $t('menu.entities.zeiterfassung') }}
                            </span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin zeiterfassung hide'"
                            :params="buildPinObject('zeiterfassung', {}, $t('menu.entities.zeiterfassung'))"
                        />
                    </li>
                    <li v-if="hasRoleArbeitszeiterfassung">
                        <router-link :to="{ name: 'zeiterfassung_mitarbeiter' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'briefcase']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.zeiterfassung_mitarbeiter') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin zeiterfassung hide'"
                            :params="buildPinObject('zeiterfassung_mitarbeiter', {}, $t('menu.entities.zeiterfassung_mitarbeiter'))"
                        />
                    </li>
                    <li v-if="hasRoleZeiterfassung || hasRoleZeiterfassungAdmin">
                        <router-link :to="{ name: 'zeiterfassung_report' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'download']" fixed-width />&nbsp;
                            <span>{{ $t('menu.admin.zeiterfassungexport') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin zeiterfassung hide'"
                            :params="buildPinObject('zeiterfassung_report', {}, $t('menu.admin.zeiterfassungexport'))"
                        />
                    </li>
                    <li v-if="hasRoleAdminMandant || hasRoleAdmin || hasRoleZeiterfassungAdmin">
                        <router-link :to="{ name: 'zem_settings_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'id-badge']" fixed-width />&nbsp;
                            <span>{{ $t('menu.admin.zemsettings') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin zeiterfassung hide'"
                            :params="buildPinObject('zem_settings_list', {}, $t('menu.admin.zemsettings'))"
                        />
                    </li>
                </ul>
            </li>
            <li v-if="hasModuleVerleih && (hasRoleVerleihAdmin || hasRoleVerleihUser)" class="hoe-has-menu">
                <a>
                    <font-awesome-icon :icon="['fad', 'share']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.entities.verleih') }}
                    </span>
                    <FavoritesPin :single="false" :area="'verleih'" />
                    <span class="selected" />
                </a>
                <ul class="hoe-sub-menu">
                    <li>
                        <router-link :to="{ name: 'verleih' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'share']" fixed-width />&nbsp;
                            <span>
                                {{ $t('menu.entities.verleih') }}
                            </span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin verleih hide'"
                            :params="buildPinObject('verleih', {}, $t('menu.entities.verleih'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'verleih_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'share']" fixed-width />&nbsp;
                            <span>
                                {{ $t('menu.entities.verleihe') }}
                            </span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin verleih hide'"
                            :params="buildPinObject('verleih_list', {}, $t('menu.entities.verleihe'))"
                        />
                    </li>
                    <li>
                        <router-link :to="{ name: 'verleih_artikel' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'tags']" fixed-width />&nbsp;
                            <span>
                                {{ $t('menu.entities.verleihartikel') }}
                            </span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin verleih hide'"
                            :params="buildPinObject('verleih_artikel', {}, $t('menu.entities.verleihartikel'))"
                        />
                    </li>
                </ul>
            </li>
            <li v-if="hasModuleQuartalskalender" class="hoe-has-menu">
                <a>
                    <font-awesome-icon :icon="['fad', 'calendar']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.entities.quarter_calendar') }}
                    </span>
                    <FavoritesPin :single="false" :area="'quartalskalender'" />
                    <span class="selected" />
                </a>
                <ul class="hoe-sub-menu">
                    <li>
                        <router-link :to="{ name: 'quartalskalender_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'calendar']" fixed-width />&nbsp;
                            <span>
                                {{ $t('menu.entities.quarter_calendar_overview') }}
                            </span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin quartalskalender_list hide'"
                            :params="buildPinObject('quartalskalender_list', {}, $t('menu.entities.quarter_calendar_overview'))"
                        />
                    </li>
                </ul>
            </li>
            <li v-if="hasRoleAdmin || hasRoleAdminMandant || (hasRoleBenachrichtigungenAdmin && hasModuleBenachrichtigungen)" class="hoe-has-menu">
                <a>
                    <font-awesome-icon :icon="['fad', 'cogs']" fixed-width />
                    <span class="menu-text">
                        {{ $t('menu.admin.main') }}
                    </span>
                    <FavoritesPin :single="false" :area="'admin'" />
                    <span class="selected" />
                </a>
                <ul class="hoe-sub-menu">
                    <li v-if="hasRoleAdmin || hasRoleAdminMandant">
                        <router-link :to="{ name: 'mandant_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'industry']" fixed-width />&nbsp;
                            <span>{{ $t('menu.entities.mandant') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin admin hide'"
                            :params="buildPinObject('mandant_list', {}, $t('menu.entities.mandant'))"
                        />
                    </li>
                    <li v-if="hasRoleAdmin || hasRoleAdminMandant">
                        <router-link :to="{ name: 'user_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'users-cog']" fixed-width />&nbsp;
                            <span>
                                {{ $t('menu.admin.user-management') }}
                            </span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin admin hide'"
                            :params="buildPinObject('user_list', {}, $t('menu.admin.user-management'))"
                        />
                    </li>
                    <li v-if="hasRoleAdmin">
                        <router-link :to="{ name: 'module_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'th']" fixed-width />&nbsp;
                            <span>{{ $t('menu.admin.module-management') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin admin hide'"
                            :params="buildPinObject('module_list', {}, $t('menu.admin.module-management'))"
                        />
                    </li>
                    <li v-if="hasRoleAdmin">
                        <router-link :to="{ name: 'group_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'object-group']" fixed-width />&nbsp;
                            <span>{{ $t('menu.admin.group-management') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin admin hide'"
                            :params="buildPinObject('group_list', {}, $t('menu.admin.group-management'))"
                        />
                    </li>
                    <li v-if="hasRoleAdmin">
                        <router-link :to="{ name: 'rechnungskreise_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'dot-circle']" fixed-width />&nbsp;
                            <span>{{ $t('menu.admin.rechnungskreise') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin admin hide'"
                            :params="buildPinObject('rechnungskreise_list', {}, $t('menu.admin.rechnungskreise'))"
                        />
                    </li>
                    <li v-if="hasRoleAdmin">
                        <router-link :to="{ name: 'belegnummerkreise_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'dot-circle']" fixed-width />&nbsp;
                            <span>{{ $t('menu.admin.belegnummerkreise') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin admin hide'"
                            :params="buildPinObject('belegnummerkreise_list', {}, $t('menu.admin.belegnummerkreise'))"
                        />
                    </li>
                    <li v-if="hasRoleAdmin || (hasRoleBenachrichtigungenAdmin && hasModuleBenachrichtigungen)">
                        <router-link :to="{ name: 'benachrichtigungen_list' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'comment-dots']" fixed-width />&nbsp;
                            <span>{{ $t('menu.admin.benachrichtigungen') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin admin hide'"
                            :params="buildPinObject('benachrichtigungen_list', {}, $t('menu.admin.benachrichtigungen'))"
                        />
                    </li>
                    <li v-if="hasRoleAdmin">
                        <router-link :to="{ name: 'datamigration' }" data-toggle="collapse" data-target=".navbar-collapse.in">
                            <font-awesome-icon :icon="['fad', 'file-export']" fixed-width />&nbsp;
                            <span> {{ $t('menu.entities.datamigration') }}</span>
                        </router-link>
                        <FavoritesPin
                            :key="selectedMandant.id"
                            :class-style="'pin admin hide'"
                            :params="buildPinObject('datamigration', {}, $t('menu.admin.datamigration'))"
                        />
                    </li>
                </ul>
            </li>
            <li v-if="hasModuleContent && (hasRoleContentReadOnly || hasRoleContentWrite || hasRoleAdmin)" class="hoe-has-menu">
                <router-link :to="{ name: 'content_list', params: { folder: 'cmsroot' } }" data-toggle="collapse" data-target=".navbar-collapse.in">
                    <font-awesome-icon :icon="['fad', 'books']" fixed-width />&nbsp;
                    <span class="menu-text">{{ $t('menu.entities.content') }}</span>
                </router-link>
                <FavoritesPin
                    :key="selectedMandant.id"
                    :class-style="'pin content single'"
                    :params="buildPinObject('content_list', {}, $t('menu.entities.content'))"
                />
            </li>
            <li v-if="hasRoleAdmin" class="hoe-has-menu">
                <a>
                    <font-awesome-icon :icon="['fad', 'info-circle']" />
                    <span class="menu-text"> Version: {{ $store.getters.appVersion.substr(1, $store.getters.appVersion.length - 2) }} </span>
                    <span class="selected" />
                </a>
            </li>
        </ul>
        <div id="expand" class="hoe-has-lastmenu" style="bottom: 80px; margin-left: 5px; cursor: pointer">
            <div id="expandIcon" class="rotateIcon">
                <font-awesome-icon :icon="['fad', 'chevron-right']" class="fa-stack-2x icon-background" />
            </div>
        </div>
        <div class="hoe-has-lastmenu" v-tooltip:top="$t('menu.help')">
            <a href="https://hoerb.at/benutzerhandbuch/" target="_blank">
                <span class="fa-stack">
                    <font-awesome-icon :icon="['fad', 'circle']" class="fa-stack-2x icon-background" />
                    <font-awesome-icon :icon="['fad', 'question-circle']" class="fa-stack-1x" style="color: white" />
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FavoritesPin from '../favorites/favoritespin';

export default {
    name: 'SidebarComponent',
    components: {
        FavoritesPin,
    },
    props: {
        msg: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters({
            hasRoleAdmin: 'auth/hasRoleAdmin',
            hasRoleUser: 'auth/hasRoleUser',
            hasRoleAdminMandant: 'auth/hasRoleAdminMandant',
            hasRoleZeiterfassung: 'auth/hasRoleZeiterfassung',
            hasRoleZeiterfassungAdmin: 'auth/hasRoleZeiterfassungAdmin',
            hasRoleArbeitszeiterfassung: 'auth/hasRoleArbeitszeiterfassung',
            hasRoleProject: 'auth/hasRoleProject',
            hasRoleLagerplatz: 'auth/hasRoleLagerplatz',
            hasRoleLagerist: 'auth/hasRoleLagerist',
            hasRoleAussendienstler: 'auth/hasRoleAussendienstler',
            hasRoleBestellungenTechniker: 'auth/hasRoleBestellungenTechniker',
            hasRoleLagerAdmin: 'auth/hasRoleLagerAdmin',
            hasRoleVerleihUser: 'auth/hasRoleVerleihUser',
            hasRoleVerleihAdmin: 'auth/hasRoleVerleihAdmin',
            hasRolePreisKalkulator: 'auth/hasRolePreisKalkulator',
            hasModulePreisKalkulator: 'mandant/selectedhasModulePreisKalkulator',
            hasModuleLagerplatz: 'mandant/selectedHasModuleLagerplatz',
            hasModuleAuswertung: 'mandant/selectedHasModuleAuswertung',
            hasModuleZeiterfassung: 'mandant/selectedHasModuleZeiterfassung',
            hasModuleKassabuch: 'mandant/selectedHasModuleKassabuch',
            hasModuleBenachrichtigungen: 'mandant/selectedHasModuleBenachrichtigungen',
            hasModuleZahlung: 'mandant/selectedHasModuleZahlung',
            hasModuleProject: 'mandant/selectedHasModuleProject',
            hasModuleBestellung: 'mandant/selectedHasModuleBestellung',
            hasModuleRechnungslegung: 'mandant/selectedHasModuleRechnungslegung',
            hasModuleVerleih: 'mandant/selectedHasModuleVerleih',
            hasModuleContent: 'mandant/selectedHasModuleContent',
            hasModuleArtikelkatalog: 'mandant/selectedHasModuleArtikelkatalog',
            hasModuleWebshop: 'mandant/selectedHasModuleWebshop',
            mandanten: 'mandant/mandanten',
            hasRoleBenachrichtigungenAdmin: 'auth/hasRoleBenachrichtigungenAdmin',
            hasRoleArtikelKatalogRead: 'auth/hasRoleArtikelKatalogRead',
            hasRoleContentWrite: 'auth/hasRoleContentWrite',
            hasRoleContentReadOnly: 'auth/hasRoleContentReadOnly',
            selectedMandant: 'mandant/selectedMandant',
            hasRoleArtikelWrite: 'auth/hasRoleArtikelWrite',
            hasModuleQuartalskalender: 'mandant/selectedHasModuleQuartalskalender',
        }),
        isAuthenticated() {
            return this.$store.state.auth.isLoggedIn;
        },
        colorImageType: function () {
            // return 'lpanel-bg8';
            if (window.location.href.indexOf('http://localhost:') === 0 || window.location.href.indexOf('http://127.0.0.1:') === 0) {
                return false;
            } else if (window.location.href.indexOf('https://dev.hoerb.at/') === 0) {
                return true;
            } else {
                return true;
            }
        },
    },
    mounted: function () {},
    methods: {
        buildPinObject(route, param, translation) {
            let pinObj = {
                route: route,
                params: param,
                translation: translation,
            };
            return pinObj;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#hoe-left-panel .lpanel-slide-out .panel-list > li.hoe-has-menu > ul.hoe-sub-menu {
    display: block !important;
}

#headerExpand {
    display: none;
}

#headerBig {
    display: none;
}
</style>

<!--language=json -->
<i18n>
{
    "de": {
        "menu": {
            "entities": {
                "main": "Entitäten",
                "adresse": "Adresse",
                "land": "Land",
                "kunde": "Kunde / Lieferant",
                "kunden": "Kunden / Lieferanten",
                "firmengruppe": "Firmengruppe",
                "mandant": "Mandant",
                "mandanten": "Mandanten",
                "mehrwertssteuer": "Mehrwertssteuer",
                "warengruppe": "Warengruppe",
                "untergruppe": "Untergruppe",
                "grundeinheit": "Grundeinheit",
                "artikel": "Artikel",
                "artikelfelddefinition": "Artikelstammfelder",
                "kundefelddefinition": "Kundenstammfelder",
                "artikelkatalog": "Artikelkatalog",
                "beleg": "Beleg",
                "belege": "Belege",
                "belegsposition": "Rechnungsposition",
                "rechnungstext": "Rechnungstext",
                "rechnungstyp": "Rechnungstyp",
                "rechnungstexttyp": "Rechnungstexttyp",
                "administration": "Verwaltung",
                "rechnungstexte": "Rechnungstexte",
                "belegstexte": "Belegstexte",
                "belegfelddefinition": "Belegfelder",
                "hotHintDefinition": "HotHintDefinition",
                "hotHintDefinitionText": "hotHintDefinitionText",
                "rechnungskreis": "Rechnungskreis",
                "rechnungsvorlage": "Belegsvorlage",
                "rechnungsvorlagen": "Belegsvorlagen",
                "auswertung": "Auswertung",
                "module": "Abrechnung",
                "zahlung": "Zahlung",
                "zahlungreport": "Report",
                "kassabuch": "Kassabuch",
                "zahlungsziel": "Zahlungsziel",
                "lieferbedingungen": "Lieferbedingungen",
                "zahlungsbedingungen": "Zahlungsbedingungen",
                "rabatt": "Rabatt",
                "preiskalk": "Preiskalkulator",
                "preisschiene": "Preisschiene",
                "zeiterfassung": "Projektzeiterfassung",
                "zeiterfassung_mitarbeiter": "Arbeitszeiterfassung",
                "projekt": "Projekt",
                "lagerplaetze": "Lagerplätze",
                "einauslagerung": "Ein/Auslagerung",
                "verleihe": "Übersicht Verleihe",
                "verleih": "Verleih",
                "verleihartikel": "Artikelübersicht",
                "projectmap": "Projektmappe",
                "newprojectmap": "Projektmappe anlegen",
                "myprojectmaps": "Meine Projektmappen",
                "bestellung": "Bestellung",
                "lager": "Lager",
                "content": "Dokumente",
                "webshop": "Webshop",
                "webshop_orders": "Webshop Bestellungen",
                "webshop_category": "Webshop Kategorie",
                "datamigration": "Datenmigration",
                "updates": "Updates",
                "uidcheck": "UID Überprüfung",
                "quarter_calendar": "Quartalskalender",
                "quarter_calendar_overview": "Quartalskalender Übersicht"
            },
            "account": {
                "main": "Zugang",
                "settings": "Einstellungen",
                "password": "Passwort",
                "sessions": "Sitzungen",
                "login": "Anmelden",
                "logout": "Abmelden",
                "register": "Registrierung"
            },
            "admin": {
                "main": "Administration",
                "user-management": "Benutzerverwaltung",
                "module-management": "Modulzuordnung",
                "group-management": "Gruppenverwaltung",
                "tracker": "Benutzer Aktivitäten",
                "metrics": "Metriken",
                "health": "Status",
                "configuration": "Konfiguration",
                "logs": "Protokoll",
                "audits": "Audits",
                "apidocs": "API",
                "database": "Datenbank",
                "rechnungskreise": "Rechnungskreise",
                "belegnummerkreise": "Belegnummernkreise",
                "zeiterfassungexport": "Report",
                "zemsettings": "Zeiterfassung Mitarbeiter",
                "benachrichtigungen": "Benachrichtigungen",
                "datamigration": "Datenmigration",
                "updates": "Updates",
                "uidcheck": "UID Check"
            },
            "language": "Sprache",
            "help": "Anleitungen anzeigen"
        },
        "en": {
            "menu": {
                "entities": {
                    "main": "Entities",
                    "adresse": "Adresse",
                    "land": "Land",
                    "kunde": "Kunde / Lieferant",
                    "kunden": "Kunden / Lieferanten",
                    "mandant": "Mandant",
                    "firmengruppe": "Firmengruppe",
                    "mehrwertssteuer": "Mehrwertssteuer",
                    "warengruppe": "Warengruppe",
                    "untergruppe": "Untergruppe",
                    "grundeinheit": "Grundeinheit",
                    "artikel": "Artikel",
                    "artikelfelddefinition": "Artikelstammfelder",
                    "artikelkatalog": "Artikelkatalog",
                    "beleg": "Rechnung",
                    "belegsposition": "Rechnungsposition",
                    "rechnungstext": "Rechnungstext",
                    "belegstexte": "Belegstexte",
                    "belegfelddefinition": "Bill fields",
                    "rechnungstyp": "Rechnungstyp",
                    "rechnungstexttyp": "Rechnungstexttyp",
                    "administration": "Administration",
                    "rechnungstexte": "Rechnungstexte",
                    "hotHintDefinition": "HotHintDefinition",
                    "hotHintDefinitionText": "HotHintDefinitionText",
                    "rechnungskreis": "Rechnungskreis",
                    "rechnungsvorlage": "Rechnungsvorlage",
                    "auswertung": "Auswertung",
                    "module": "Abrechnung",
                    "zahlung": "Zahlung",
                    "zahlungreport": "Report",
                    "kassabuch": "Kassabuch",
                    "zahlungsziel": "Zahlungsziel",
                    "lieferbedingungen": "Lieferbedingungen",
                    "zahlungsbedingungen": "Zahlungsbedingungen",
                    "rabatt": "Discount",
                    "preiskalk": "Price Calculator",
                    "preisschiene": "Prices",
                    "zeiterfassung": "Projektzeiterfassung",
                    "projekt": "Project",
                    "lagerplaetze": "Lagerplätze",
                    "einauslagerung": "Ein/Auslagerung",
                    "verleihe": "Übersicht Verleihe",
                    "verleih": "Verleih",
                    "verleihartikel": "Artikel Overview",
                    "bestellung": "Order",
                    "lager": "Stock",
                    "content": "Documents",
                    "datamigration": "Datamigration",
                    "updates": "Updates",
                    "webshop": "Webshop",
                    "webshop_orders": "Webshop Orders",
                    "webshop_category": "Webshop Category",
                    "quarter_calendar": "Quarter Calendar",
                    "quarter_calendar_overview": "Quarter Calendar Overview"
                },
                "account": {
                    "main": "Account",
                    "settings": "Settings",
                    "password": "Password",
                    "sessions": "Sessions",
                    "login": "Sign in",
                    "logout": "Sign out",
                    "register": "Register"
                },
                "admin": {
                    "main": "Administration",
                    "user-management": "User management",
                    "module-management": "Module management",
                    "group-management": "Group management",
                    "tracker": "User tracker",
                    "metrics": "Metrics",
                    "health": "Health",
                    "configuration": "Configuration",
                    "logs": "Logs",
                    "audits": "Audits",
                    "apidocs": "API",
                    "database": "Database",
                    "rechnungskreise": "Rechnungskreise",
                    "belegnummerkreise": "Belegnummernkreise",
                    "zemsettings": "Zeiterfassung Mitarbeiter",
                    "zeiterfassungexport": "Report",
                    "benachrichtigungen": "Benachrichtigungen",
                    "datamigration": "Datenmigration",
                    "updates": "Updates",
                    "uidcheck": "UID Check"
                },
                "language": "Language",
                "help": "Show instructions"
            }
        }
    }
}


</i18n>
